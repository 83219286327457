import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import FieldWrapper from '@/forms/FormElements/Common/FieldWrapper/FieldWrapper.vue';
import ErrorWrapper from '@/forms/FormElements/Common/ErrorWrapper/ErrorWrapper.vue';
import TextInput from '@/forms/FormElements/Inputs/TextInput/TextInput.vue';

import RowValidationMixin from '@/forms/FormElements/Mixins/RowValidationMixin';

import { CleaveOptions } from 'cleave.js/options';

@Component({ components: { FieldWrapper, TextInput, ErrorWrapper }, inheritAttrs: false })
export default class TextRow extends mixins(RowValidationMixin) {
  @Prop({ type: Boolean, default: false })
  readonly textarea!: boolean;

  @Prop({ type: String, default: null })
  readonly sublabel!: string;

  @Prop({ type: Object as () => CleaveOptions, default: null })
  readonly formatter!: CleaveOptions;
}
