import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js';
import InputValidationMixin from '@/forms/FormElements/Mixins/InputValidationMixin';

@Component({})
export default class TextInput extends mixins(InputValidationMixin) {
  @Prop({ type: Boolean, default: false })
  readonly textarea!: boolean;

  @Prop({ type: Object as () => CleaveOptions, default: null })
  readonly formatter!: CleaveOptions;

  @Prop()
  readonly id!: string;

  @Prop()
  readonly filterWatch!: boolean;

  get domTag() {
    return this.textarea ? 'textarea' : 'input';
  }

  get inputClass() {
    return this.textarea ? 'field-wrapper__textarea' : 'field-wrapper__text-input';
  }

  @Ref('inputElement') readonly inputElement!: HTMLInputElement;

  @Watch('value')
  valueChanged(newValue: string) {
    if (this.filterWatch) return;

    if (this.formatter) {
      const { numericOnly } = this.formatter;
      const cleanValue = numericOnly ? newValue.toString().replace(/\D/g, '') : newValue;
      if (cleanValue !== this.cleaveWrapper.getRawValue()) {
        this.cleaveWrapper.setRawValue(newValue);
      }
    } else if (this.inputElement && this.inputElement.value !== newValue) {
      this.inputElement.value = newValue;
    }
  }

  textInput(value: any) {
    if (!this.formatter) {
      this.input(value);
    }
  }

  cleaveWrapper!: Cleave;
  mounted() {
    if (this.formatter) {
      this.formatter.onValueChanged = (e: any) => {
        const value = e.target.rawValue;
        if (value !== this.value) {
          this.input(e.target.rawValue);
        }
      };

      this.cleaveWrapper = new Cleave(this.inputElement, this.formatter);
    }
  }
}
