import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FieldWrapper extends Vue {
  @Prop(String)
  readonly label!: string;
  @Prop(Boolean)
  readonly required!: boolean;
  @Prop(Boolean)
  readonly error!: boolean;
  @Prop(Boolean)
  readonly disabled!: boolean;
  @Prop(String)
  readonly htmlFor?: string;
}
