import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';

import Message from '@/forms/Validations/Message';

import { getPathByParts } from '@/utils/commonUtils';
import { replaceAll } from '@/utils/stringUtils';

@Component
export default class RowValidationMixin extends Vue {
  @Prop(String)
  readonly label!: string;
  @Prop(String)
  readonly name!: string;
  @Prop(String)
  readonly subModel!: string;
  @Prop({ type: Boolean, default: false })
  readonly required!: boolean;
  @Prop(String)
  readonly errorMessage!: string;
  @Prop(Boolean)
  readonly isError!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;
  @Prop({ type: [String, Number], default: '' })
  readonly idPrefix!: string;

  @Prop({ type: Object, default: null })
  readonly separateModel!: any;

  @Inject({ default: () => null }) inputToModel!: (
    name: string,
    value: any,
    isBlur?: boolean,
  ) => void;
  @Inject({ default: () => null }) getModel!: (subModel: string) => any;
  @Inject({ default: () => null }) getValidation!: (name: string) => any;
  @Inject({ default: () => null }) getErrorMessages!: (name: string) => Message[];

  model!: any;
  validation!: any;
  messages!: Message[];

  created() {
    if (this.separateModel) {
      this.model = this.separateModel;
    } else {
      this.model = this.getModel(this.getPath());
      this.validation = this.getValidation(this.getPath());
      this.messages = this.getErrorMessages(this.getKeyFormMessages());
    }
  }

  input(name: string, value: any, isBlur: boolean) {
    this.$emit('input', value);
    if (this.inputToModel) {
      this.inputToModel(this.getPath(name), value, isBlur);
    }
  }

  getCurrentMessage(name: string) {
    let message: Message | undefined;

    if (this.isError && this.errorMessage) {
      return this.errorMessage;
    }

    if (this.separateModel) {
      return '';
    }

    if (this.validation[name] && this.validation[name].$error) {
      const key = this.getKeyFormMessages(name);

      message = this.messages
        .filter((m: Message) => m.key === key)
        .find((m: Message) => this.validation[name][m.rule] === false);
    }

    if (message) {
      return message.messageFunc
        ? message.messageFunc(this.model[name], this.model)
        : message.message;
    }
    return '';
  }

  isRequired(name: string) {
    if (this.required) {
      return true;
    }

    if (this.separateModel) {
      return false;
    }

    const v = this.validation[name];
    if (v) {
      return v.required !== undefined;
    }

    return false;
  }

  getKeyFormMessages(name?: string) {
    return replaceAll(this.getPath(name), /\[[0-9]{1,100}\]/g, '[]');
  }

  getPath(name?: string) {
    return getPathByParts(this.subModel, name);
  }

  get generateFieldId() {
    return `${this.subModel ? this.subModel : ''}-${this.name ? this.name : ''}${this.idPrefix ||
      ''}`;
  }
}
