import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ErrorWrapper extends Vue {
  @Prop(String)
  readonly message!: string;
  @Prop({ default: false, type: Boolean })
  readonly warning!: boolean;

  get messageClass() {
    if (this.warning) {
      // TODO add class for warning level
    }
    return 'error-message';
  }
}
